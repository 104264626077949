import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './about.module.scss'
import {Link} from 'gatsby'
import AboutUsImg from '../components/about/about-image'
import CoreyImg from '../components/about/corey-image'
import AnhImg from '../components/about/anh-image'
import closingDoubleQuote from '../images/closing-double-quote.svg'

const aboutSection = 'MODE was founded in Melbourne to enhance the fashion lives of consumers and creators through social commerce. Realising the disconnect between where people find inspiration, share outfits and shop, the goal was to provide a seamless journey from natural discovery to shopping.';

const BrandPage = () => (
  <Layout>
    <SEO title='About' />

    <div className='container mx-auto mb-12'>
      <div className='px-4 lg:px-8'>
        <div className='relative'>
          <div className='flex flex-col md:flex-row'>
            <h1 className={`text-2xl sm:text-4xl mr-10 md:mt-16 mb-4 ${styles.heading}`} >
              About us
            </h1>
            <div className={styles.img}>
              <AboutUsImg />
            </div>
          </div>

          <div className={`text-sm p-6 absolute hidden md:block ${styles.aboutSectionMedium}`}>
            {aboutSection}
          </div>

          <div className={`text-sm p-6 block md:hidden ${styles.aboutSection}`}>
            {aboutSection}
          </div>
        </div>
      </div>

      <div className='md:px-4 lg:px-24 mt-12 relative'>
        <p className='bg-black text-white p-8 text-lg font-bold text-center'>
          To be stylish is to communicate your personality through fashion. We believe that finding and expanding your style should be simple; shopping should be about discovery and inspiration should come from real people.
        </p>
        <div className={`hidden lg:block absolute ${styles.closingDoubleQuote}`}>
          <img src={closingDoubleQuote} alt='Closing double quote' />
        </div>
      </div>

      <div className='px-4 lg:px-24 mt-12 flex'>
        <div className={`h-48 w-48 mr-6 hidden md:block ${styles.avatar}`}>
          <CoreyImg />
        </div>
        <div className='flex flex-col md:block items-center'>
          <div className='font-bold text-lg mb-1'>Corey James</div>
          <div className='text-xs text-gray-600 mb-4'>CEO and co-founder</div>
          <div className={`h-48 w-48 block md:hidden ${styles.avatarMedium}`}>
            <CoreyImg />
          </div>
          <div className='text-sm'>Experiencing first hand the difficulties of finding good fashion inspiration and witnessing the difficulties for labels in the cluttered online space of ecommerce, embarked on a mission to make a meaningful change in the industry. Always having a keen eye for fashion and wanting to push the boundaries of technology in fashion, MODE was the brainchild of many prototypes, coffee, converations with consumers/creators/brands, sweat and tears.</div>
        </div>
      </div>

      <div className='px-4 lg:px-24 mt-12 flex'>
        <div className={`h-48 w-48 mr-6 hidden md:block ${styles.avatar}`}>
          <AnhImg />
        </div>
        <div className='flex flex-col md:block items-center'>
          <div className='font-bold text-lg mb-1'>Anh Do</div>
          <div className='text-xs text-gray-600 mb-4'>CTO and co-founder</div>
          <div className={`h-48 w-48 block md:hidden ${styles.avatarMedium}`}>
            <AnhImg />
          </div>
          <div className='text-sm'>A skilled software engineer who has has worked previously for major companies and startups alike, Anh saw to the dream and had the pracitcal skills to turn it into fruition. Having completed studies at RMIT in both a Bachelor of Business and Business Information Systems, Anh combines technology whilst keeping a wholelistic view of the business. As the co-founder of MODE, Anh is a driving force behind the strategy of the platform and its core techology.</div>
        </div>
      </div>

      {/*
      <div className='px-4 lg:px-8 font-bold text-center text-lg m-10'>Our team</div>
      <div className='px-4 lg:px-8 flex flex-wrap'>
        <div className='w-1/2 lg:w-1/4 px-4'>
          <div className='bg-gray-400 h-48 w-40'></div>
          <div className='m-3 text-sm'>John Doe</div>
          <div className='m-3 text-xs'>Colleague</div>
        </div>
        <div className='w-1/2 lg:w-1/4 px-4'>
          <div className='bg-gray-400 h-48 w-40'></div>
          <div className='m-3 text-sm'>Jane Doe</div>
          <div className='m-3 text-xs'>Colleague</div>
        </div>
        <div className='w-1/2 lg:w-1/4 px-4'>
          <div className='bg-gray-400 h-48 w-40'></div>
          <div className='m-3 text-sm'>John Doe</div>
          <div className='m-3 text-xs'>Colleague</div>
        </div>
        <div className='w-1/2 lg:w-1/4 px-4'>
          <div className='bg-gray-400 h-48 w-40'></div>
          <div className='m-3 text-sm'>Jane Doe</div>
          <div className='m-3 text-xs'>Colleague</div>
        </div>
      </div>
      */}

      <div className='px-4 lg:px-8 mt-12 flex flex-col items-center'>
        <div className='text-lg font-bold mt-4'>Need to contact us?</div>
        <div className='text-xs mt-2'>Get in touch for queries, career opportunities & investment</div>
        <button className='bg-black hover:bg-gray-800 text-white font-bold text-sm uppercase py-2 px-4 rounded-sm my-3'>
          <Link to='/contact/'>Contact</Link>
        </button>
      </div>
    </div>
  </Layout>
)

export default BrandPage
